import { Button, Divider, Input, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import axios from "axios";
import { useDispatch } from "react-redux";
import { ChatBotImage, ErrorIcon } from "../../../../Assets/Export/Export";
import { LeaveConversations } from "../../../../Utils/LeaveConversatio";
import { setSelectChat } from "../../../../Redux/Slice/selectedConversation";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { setConversationsForChat, setLoadingForChat } from "../../../../Redux/Slice/ChatConversationslice";
import InfiniteScroll from "react-infinite-scroll-component";
import Showdown from "showdown";

function ChatInput({
  type,
  chatHistory,
  name,
  conversationType,
  recordId,
  loadingForChat,
}) {
  const bottomRef = useRef(null);

  var converter = new Showdown.Converter();
  const [inputMessage, setInputMessage] = useState("");
  const [chatHistorys, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   bottomRef.current.scrollIntoView({ behavior: "auto" });
  // }, [chatHistory]);

  useEffect(() => {
    setChatHistory(chatHistory);
    setPage(1);
    bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
  }, [chatHistory]);

  const sendMessage = async (inputMessage) => {
    setLoading(true);
    try {
      const res = await axios.post("/conversation/send/message/", {
        message: inputMessage,
        conversation_id: conversationType.id,
      });
      setLoading(false);
    } catch (err) {
      message.error("Failed to send message");
      setLoading(false);
    }
  };

  const transferConversation = async () => {
    try {
      const res = await axios.post(
        "/conversation/transfer_to_me/",
        {
          conversation_id: conversationType.id,
        }
      );
    } catch (err) {
      message.error("Failed to transfer conversation");
    }
  };



  function Format(html, request) {

    if (!html) {
      return html;
    }

    return formatWhatsAppText(html, request);
 
  }
  function AnchorTagDetect(text, request) {

    // Modified regex to allow spaces within URLs and remove them
    var markdownLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
    var urlRegex = /(https?:\/\/[^\s\.]+[^\s]*)/g;
    
    // Function to remove spaces from URLs
    function removeSpaces(url) {
      return url.replace(/\s+/g, '');
    }
    
    text = text.replace(markdownLinkRegex, function (match, textContent, url) {
      url = removeSpaces(url);  // Remove spaces from URL
      var style = request === "request" ? 'color:#000;font-weight:bold;text-decoration:underline' : 'color:#fff;font-weight:bold;text-decoration:underline';
      return '<a target="_blank" style="white-space:break-spaces; word-break:break-all;' + style + '" href="' + url + '">' + textContent + '</a>';
    });
    
    text = text.replace(urlRegex, function (url) {
      url = removeSpaces(url);  // Remove spaces from URL
      
      // Remove trailing period if present
      if (url.endsWith('.')) {
        url = url.slice(0, -1);
      }
    
      if (text.includes('href="' + url + '')) {
        return url;
      }
      var style = request === "request" ? 'color:#000;font-weight:bold;text-decoration:underline' : 'color:#fff;font-weight:bold;text-decoration:underline';
      return '<a target="_blank" style="white-space:break-spaces; word-break:break-all;' + style + '" href="' + url + '">' + url + '</a>';
    });
    

    return converter.makeHtml(text);
  }
  function formatWhatsAppText(text, request) {
    let italicRegex = /(^|[^*_])\*([^*_]+)\*(?=[^*_]|$)/g;
    let boldRegex = /(^|[^*_])\*\*([^*_]+)\*\*(?=[^*_]|$)/g;
    let strikethroughRegex = /(^|[^*_])~([^*_]+)~(?=[^*_]|$)/g;
    let monospaceRegex = /(^|[^*_])```([^*_]+)```(?=[^*_]|$)/g;
    let bulletedListRegex = /(?:^|\n)(\*|-)\s+(.*?)(?=\n|$)/g;
    let numberedListRegex = /(?:^|\n)(\d+\.\s)(.*?)(?=\n|$)/g;
    let quoteRegex = /(?:^|\n)(>\s)(.*?)(?=\n|$)/g;
    let inlineCodeRegex = /(^|[^`])`([^`]+)`(?=[^`]|$)/g;

    // Regex to match HTML tags
    const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;

    // Function to check if text contains HTML
    function isHTML(content) {
      return htmlTagRegex.test(content);
    }

    // Function to escape HTML tags
    function escapeHTML(content) {
      return content.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    }

    // Function to unescape HTML tags
    function unescapeHTML(content) {
      return content.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    }

    // Function to process non-HTML parts
    function formatText(text) {
      return text
      // .replace(italicRegex, '$1<em>$2</em>')
      // .replace(boldRegex, '$1<strong>$2</strong>')
      // .replace(strikethroughRegex, '$1<del>$2</del>')
      // .replace(monospaceRegex, '$1<code>$2</code>')
      // .replace(bulletedListRegex, '<ul style="padding:0 20px"><li>$2</li></ul>')
      // .replace(numberedListRegex, '<ol><li>$2</li></ol>')
      // .replace(quoteRegex, '<blockquote>$2</blockquote>')
      // .replace(inlineCodeRegex, '$1<code>$2</code>');
    }

    // Split text into HTML and non-HTML parts
    let htmlParts = [];
    let nonHtmlParts = [];
    let lastIndex = 0;

    text.replace(htmlTagRegex, (match, offset) => {
      if (offset > lastIndex) {
        nonHtmlParts.push(text.slice(lastIndex, offset));
      }
      htmlParts.push(match);
      lastIndex = offset + match.length;
      return match;
    });

    if (lastIndex < text.length) {
      nonHtmlParts.push(text.slice(lastIndex));
    }

    // Apply formatting to non-HTML content
    let formattedNonHtml = nonHtmlParts.map(part => formatText(part)).join('');

    // Reconstruct the full formatted text
    let formattedText = htmlParts.reduce((acc, html, index) => {
      return acc + formattedNonHtml.slice(0, htmlParts[index].start) + html + formattedNonHtml.slice(htmlParts[index].end);
    }, '');

    // Ensure all remaining non-HTML text is included
    formattedText += nonHtmlParts.slice(htmlParts.length).join('');

    return AnchorTagDetect(formattedText, request);
  }

  const [page, setPage] = useState(1);
  const getChatHistory = async (page) => {
    // dispatch(setLoadingForChat(true));
    setLoading2(true);
    try {
      const res = await axios.get(
        `/conversation/chat_history/?conversation_id=${conversationType.id}&batch=${page}&pageSize=40`
      );

      if (res.data.messages.length === 0) {
        setHasMore(false);
        return;
      }

      if (res.data.messages.length < 40) {
        setHasMore(false);
        return;
      }



      setChatHistory((prev) => {
        return [...prev, ...res.data.messages]
      });
      // dispatch(setLoadingForChat(false));
    } catch (err) {
      message.error("Failed to get chat history");
      console.log(err);

    }
    setLoading2(false);
  };


  return (
    <>
      <div className="flex-1 flex-col flex justify-end h-full overflow-hidden">
        <div className="h-[75vh] overflow-auto flex flex-col gap-4  ">
          {!loadingForChat ? (
            <div ref={bottomRef} className="overflow-auto flex gap-4 flex-col-reverse" id="scrollableDiv">
              <InfiniteScroll

                dataLength={chatHistorys.length}
                next={() => {
                  getChatHistory(page + 1);
                  setPage((prev) => prev + 1);
                }}
                style={{ display: 'flex', flexDirection: 'column-reverse', gap: "16px", padding: "10px 12px" }} //To put endMessage and loader to the top.
                inverse={true} //
                hasMore={hasMore}
                loader={
                  loading2 ?
                    <div className="w-full h-full flex items-center justify-center text-[#0766FF] text-2xl">
                      <LoadingOutlined />
                    </div>
                    : null
                }

                scrollableTarget="scrollableDiv"
              >
                {chatHistorys.map((item, index) => {
                  return (
                    <>
                      {item.message_type === "request" ? (
                        <>
                          <div className="flex justify-start gap-1 items-end ">
                            <div className="w-[30px] h-[30px] rounded-full flex items-center">
                              <img
                                className="rounded-full"
                                src={`https://api.dicebear.com/7.x/initials/svg?seed=${name}`}
                              />
                            </div>
                            <div
                              style={{
                                maxWidth: "300px",
                                minWidth: "250px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "10px",
                                }}
                                className="flex justify-between w-full"
                              >
                                <div
                                  className="text-[#616161] text-xs"
                                  style={{
                                    width: "40%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {name}
                                </div>
                                <div
                                  style={{
                                    width: "40%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  className="text-[#616161] text-xs text-right"
                                >
                                  {item.created_at_formatted}
                                </div>
                              </div>
                              <div
                                className="rounded-2xl bg-[#fff] p-2 url-color-request ul-request"
                                style={{ whiteSpace: "pre-line", wordBreak: "normal" }}
                                dangerouslySetInnerHTML={{ __html: Format(item.message, "request") }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        item.message_type === "response" ?
                          <>
                            <div className="flex justify-end items-end gap-1">
                              <div
                                style={{
                                  maxWidth: "300px",
                                  minWidth: "250px",
                                }}
                              >
                                <div className="flex justify-between">
                                  <div className="text-[#616161] text-xs">
                                    {item.agent ? item?.agent?.name : "ChatBot"}
                                  </div>
                                  <div className="text-[#616161] text-xs">
                                    {item.created_at_formatted}
                                  </div>
                                </div>

                                <div
                                  className="rounded-2xl bg-[#fff] p-2 url-color-sender ul-sender"
                                  style={{
                                    background: item.agent ? "#fff" : "#1655F8",
                                    color: item.agent ? "#000" : "#fff",
                                    whiteSpace: "pre-line",
                                    wordBreak: "normal"
                                  }}
                                  dangerouslySetInnerHTML={{ __html: Format(item.message, "sender") }}
                                />

                              </div>
                              <div className="w-[30px] h-[30px] rounded-full flex items-center">
                                {item.agent?.name ? (
                                  <img
                                    className="rounded-full w-full h-full"
                                    src={`https://api.dicebear.com/7.x/initials/svg?seed=${item.agent?.name}`}
                                  />
                                ) : (
                                  <ChatBotImage />
                                )}
                              </div>
                            </div>
                          </>
                          : (
                            <>
                              <div className="text-center font-bold text-xs">
                                {item.message}
                              </div>
                            </>
                          )
                      )}
                    </>
                  );
                })}
              </InfiniteScroll>
            </div>
          ) : (
            <div className="w-full h-full flex items-center justify-center text-[#0766FF] text-2xl">
              <LoadingOutlined />
            </div>
          )}
          {/* <div ref={bottomRef}></div> */}
        </div>
        {/* <div className="mb-4">
          <Divider>Jan 11 2024</Divider>
          <div className="text-center">
            Converstion has been transfer to sales team at 11:05 AM
          </div>
        </div> */}
        <div
          style={{
            borderTop: "4px solid var(--primary, #1655F8)",
          }}
        >
          {conversationType.is_assigned &&
            conversationType.agent?.record_id ==
            localStorage.getItem("user_id") && (
              <>
                <Divider
                  style={{
                    margin: 0,
                  }}
                />
                <div className="p-4 bg-[#fff]">
                  <Input
                    onChange={(e) => {
                      setInputMessage(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (
                        !loading &&
                        inputMessage.length !== 0 &&
                        e.key === "Enter"
                      ) {
                        sendMessage(inputMessage);
                        setInputMessage("");
                      }
                    }}
                    value={inputMessage}
                    suffix={
                      <div className="cursor-pointer">
                        <Button
                          loading={loading}
                          icon={<SendOutlined />}
                          onClick={() => {
                            sendMessage(inputMessage);
                            setInputMessage("");
                          }}
                          disabled={inputMessage.length === 0}
                        ></Button>
                      </div>
                    }
                    placeholder="Type a message"
                  />
                  <div></div>
                </div>
              </>
            )}
          <>
            {conversationType.is_assigned &&
              conversationType.agent?.record_id !=
              localStorage.getItem("user_id") && (
                <div className="flex p-4  justify-center bg-[#fff]">
                  <div
                    onClick={transferConversation}
                    className="px-6 py-2 bg-[#19334E] cursor-pointer flex justify-center items-center"
                  >
                    <div className="font-semibold text-[14px] text-[#fff]">
                      Transfer Conversation to me
                    </div>
                  </div>
                </div>
              )}

            {!conversationType?.chatbot_user?.whatsapp_id && !conversationType.is_assigned && (
              <div className="flex p-4  justify-center bg-[#fff]">
                <div
                  onClick={() => {
                    LeaveConversations("assignment", conversationType.id);
                  }}
                  className="cursor-pointer px-6 py-2 bg-[#19334E] flex justify-center items-center"
                >
                  <div className="font-semibold text-[14px] text-[#fff]">
                    Join Conversation
                  </div>
                </div>
              </div>
            )}
            {(conversationType?.chatbot_user?.whatsapp_id) &&

              <>
                {
                  (moment(conversationType.latest_message_time, 'HH:mm:ss DD-MM-YYYY').isAfter(moment().subtract(1, 'days'))) ?
                    !conversationType.agent?.record_id && <div className="flex p-4  justify-center bg-[#fff]">
                      <div
                        onClick={() => {
                          LeaveConversations("assignment", conversationType.id);
                        }}
                        className="cursor-pointer px-6 py-2 bg-[#19334E] flex justify-center items-center"
                      >
                        <div className="font-semibold text-[14px] text-[#fff]">
                          Join Conversation
                        </div>
                      </div>
                    </div> :

                    <div className="flex p-4 justify-center bg-[#fff]">

                      <div className="font-semibold text-[14px] px-6 py-2  text-[#000] flex justify-center items-center gap-2">
                        <ErrorIcon /> WhatsApp conversation can be responded within 24 hours only.
                      </div>

                    </div>
                }
              </>

            }
          </>
        </div>
      </div>
    </>
  );
}

export default ChatInput;